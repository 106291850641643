import React from 'react';

const Features: React.FC = () => {
  return (
    <section id="features" className="features">
      <h2>Our Features</h2>
      <div className="feature-grid">
        <div className="feature-card">
          <h3>Novel TADAR Sensing</h3>
          <p>Advanced sensing technology for precise human behavior detection.</p>
        </div>
        <div className="feature-card">
          <h3>Advanced Sensor Fusion</h3>
          <p>Combining multiple sensor inputs for comprehensive spatial awareness.</p>
        </div>
        <div className="feature-card">
          <h3>Efficient Edge AI</h3>
          <p>On-device processing for real-time analysis and reduced data transmission.</p>
        </div>
        <div className="feature-card">
          <h3>Spatial AI</h3>
          <p>Intelligent space mapping and utilization analysis for optimized environments.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;